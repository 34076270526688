html{
    overflow-y:  hidden !important;
}
body{
    overflow-y: auto !important;
}
#header-main .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
}
  
#header-main .trigger:hover {
    color: #1890ff;
}

#header-main .logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}
.table-clabs .row-clabs{
    background: #f9f6f6;
}
.ant-menu-dark .ant-menu-item-selected{
    background-color: #722ed1 !important;
}
.ant-dropdown-menu-item-active{
    background-color: #f9f0ff !important;
}
.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after{
    border-right: 3px solid #722ed1;
}
.cl-menu-main-right{
    float: right;
    height: 100%;
    overflow: hidden;
    margin-right: 20px;
}
.drwawer-padding-0 .ant-drawer-body{
    padding: 0px;
}